import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/Layout/Layout.vue";
import Main from "@/components/Layout/Main.vue";
import store from '@/store'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
	return originalPush.call(this, location).catch(err=>err)
}

const routes = [{
		path: '/',
		name: 'home',
		component: Layout,
		redirect: '/home',
		children: [{
				path: '/home',
				name: 'home',
				title: '首页',
				meta: {
					title: '首页',
				},
				menuHide: true,
				component: () => import( /* webpackChunkName: "about" */ '../views/home.vue')
			},
			// {
			// 	path: '/deposit',
			// 	name: 'deposit',
			// 	title: '缴存项目',
			// 	meta: {
			// 		title: '缴存项目',
			// 	},
			// 	menuHide: true,
			// 	component: Main,
			// 	children: [{
			// 		path: '/deposit/system',
			// 		name: 'system',
			// 		title: '系统管理',
			// 		meta: {
			// 			title: '系统管理',
			// 		},
			// 		component: Main,
			// 		icon: 'control',
			// 		children: [{
			// 				path: '/deposit/system/userlist',
			// 				name: 'userlist',
			// 				title: '用户管理',
			// 				meta: {
			// 					title: '用户管理',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/system/userlist.vue')
			// 			},
			// 			{
			// 				path: '/deposit/system/mechanism',
			// 				name: 'mechanism',
			// 				title: '操作员',
			// 				meta: {
			// 					title: '操作员',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/system/mechanism.vue')
			// 			},
			// 			{
			// 				path: '/deposit/system/menu',
			// 				name: 'menu',
			// 				title: '菜单管理',
			// 				meta: {
			// 					title: '菜单管理',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/system/menu.vue')
			// 			}, {
			// 				path: '/deposit/system/client',
			// 				name: 'client',
			// 				title: '客户管理',
			// 				meta: {
			// 					title: '客户管理',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/system/client.vue')
			// 			}, {
			// 				path: '/deposit/system/outlets',
			// 				name: 'outlets',
			// 				title: '网点管理',
			// 				meta: {
			// 					title: '网点管理',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/system/outlets.vue')
			// 			}, {
			// 				path: '/deposit/system/role',
			// 				name: 'role',
			// 				title: '角色管理',
			// 				meta: {
			// 					title: '角色管理',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/system/role.vue')
			// 			}
			// 		]
			// 	}, {
			// 		path: '/deposit/parameter',
			// 		name: 'parameter',
			// 		title: '参数管理',
			// 		meta: {
			// 			title: '参数管理',
			// 		},
			// 		component: Main,
			// 		icon: 'block',
			// 		children: [{
			// 				path: '/deposit/parameter/deposit',
			// 				name: 'deposit',
			// 				title: '存款类型',
			// 				meta: {
			// 					title: '存款类型',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/parameter/deposit.vue')
			// 			},
			// 			{
			// 				path: '/deposit/parameter/dayend',
			// 				name: 'dayend',
			// 				title: '日结方式',
			// 				meta: {
			// 					title: '日结方式',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/parameter/dayend.vue')
			// 			},
			// 		]
			// 	}, {
			// 		path: '/deposit/terminal',
			// 		name: 'terminal',
			// 		title: '终端管理',
			// 		meta: {
			// 			title: '终端管理',
			// 		},
			// 		component: Main,
			// 		icon: 'code',
			// 		children: [{
			// 				path: '/deposit/terminal/equipment',
			// 				name: 'equipment',
			// 				title: '设备管理',
			// 				meta: {
			// 					title: '设备管理',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/terminal/equipment.vue')
			// 			}
			// 			, {
			// 				path: '/deposit/terminal/state',
			// 				name: 'state',
			// 				title: '设备状态',
			// 				meta: {
			// 					title: '设备状态',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/terminal/state.vue')
			// 			}
			// 			, {
			// 				path: '/deposit/terminal/brand',
			// 				name: 'brand',
			// 				title: '设备类型',
			// 				meta: {
			// 					title: '设备类型',
			// 				},
			// 				menuHide: true,
			// 				component: () => import( /* webpackChunkName: "about" */
			// 					'../views/deposit/terminal/brand.vue')
			// 			}
			// 		]
			// 	}, {
			// 		path: '/deposit/dynamic',
			// 		name: 'dynamic',
			// 		title: '动态密码锁管理',
			// 		meta: {
			// 			title: '动态密码锁管理',
			// 		},
			// 		component: Main,
			// 		icon: 'lock',
			// 		children: [{
			// 			path: '/deposit/dynamic/lock',
			// 			name: 'lock',
			// 			title: '锁具管理',
			// 			meta: {
			// 				title: '锁具管理',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/dynamic/lock.vue')
			// 		}, {
			// 			path: '/deposit/dynamic/task',
			// 			name: 'task',
			// 			title: '任务管理',
			// 			meta: {
			// 				title: '任务管理',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/dynamic/task.vue')
			// 		}]
			// 	}, {
			// 		path: '/deposit/deposit',
			// 		name: 'deposit',
			// 		title: '存款查询',
			// 		meta: {
			// 			title: '存款查询',
			// 		},
			// 		component: Main,
			// 		icon: 'transaction',
			// 		children: [{
			// 			path: '/deposit/deposit/deposit_record',
			// 			name: 'deposit_record',
			// 			title: '存款记录',
			// 			meta: {
			// 				title: '存款记录',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/deposit/deposit_record.vue')
			// 		}, {
			// 			path: '/deposit/deposit/on_board',
			// 			name: 'on_board',
			// 			title: '在机金额',
			// 			meta: {
			// 				title: '在机金额',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/deposit/on_board.vue')
			// 		}, {
			// 			path: '/deposit/deposit/deposit_summary',
			// 			name: 'deposit_summary',
			// 			title: '存款汇总',
			// 			meta: {
			// 				title: '存款汇总',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/deposit/deposit_summary.vue')
			// 		}, {
			// 			path: '/deposit/deposit/title_number',
			// 			name: 'title_number',
			// 			title: '冠字号查询',
			// 			meta: {
			// 				title: '冠字号查询',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/deposit/title_number.vue')
			// 		}]
			// 	}, {
			// 		path: '/deposit/day_end',
			// 		name: 'day_end',
			// 		meta: {
			// 			title: '日结管理',
			// 		},
			// 		title: '日结管理',
			// 		component: Main,
			// 		icon: 'file-done',
			// 		children: [{
			// 			path: '/deposit/day_end/record',
			// 			name: 'record',
			// 			title: '日结记录',
			// 			meta: {
			// 				title: '日结记录',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/day_end/record.vue')
			// 		}, {
			// 			path: '/deposit/day_end/day_end',
			// 			name: 'day_end',
			// 			title: '日结',
			// 			meta: {
			// 				title: '日结',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/day_end/day_end.vue')
			// 		}]
			// 	}, {
			// 		path: '/deposit/clean_machine',
			// 		name: 'clean_machine',
			// 		title: '清机管理',
			// 		meta: {
			// 			title: '清机管理',
			// 		},
			// 		component: Main,
			// 		icon: 'interaction',
			// 		children: [{
			// 			path: '/deposit/clean_machine/record',
			// 			name: 'record',
			// 			title: '清机记录',
			// 			meta: {
			// 				title: '清机记录',
			// 			},
			// 			menuHide: true,
			// 			component: () => import( /* webpackChunkName: "about" */
			// 				'../views/deposit/clean_machine/record.vue')
			// 		}]
			// 	}],
			// },
			{
				path: '/userinfo',
				name: 'userinfo',
				title: '用户中心',
				meta: {
					title: '用户中心',
				},
				menuHide: true,
				component: () => import( /* webpackChunkName: "about" */ '../views/userinfo.vue')
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
	},

]

const router = new VueRouter({
	routes
})

var initRouter = false

function getRouterList(data) {
	function forData(list) {
		list = list.map((v) => {
			let d = {
				path: v.router,
				name: v.router_en || v.router_name,
				title: v.router_name,
				component: '',
				meta: {
					title: v.router_name,
				},
				icon: v.icon,
				children: v.list || []
			}
			if (v.component == "Main") {
				d.component = Main
			} else {
				d.component = (resolve) => require([`@/views${v.component}`], resolve)
			}
			if (v.perms == 'hide') {
				d.hidden = true
			}
			if (v.list && v.list.length >= 1) {
				d.redirect = v.list[0].url
				let ar = forData(d.children || [])
				d.children = ar.length == 0 ? undefined : ar
				return d
			} else {
				return d
			}
		})
		return list
	}
	data = forData(data)
	return data

	return initRouterList
}

router.beforeEach((to, from, next) => {
	if (to.name !== 'login' && !localStorage.getItem("token")) {
		next({
			name: 'login'
		})
	} else {
		if (localStorage.getItem('authority')) {
			let authority = JSON.parse(localStorage.getItem('authority'))
			if (!initRouter) {
				let routerData = getRouterList(authority)
				router.addRoutes([{
					path: '/deposit',
					name: 'deposit',
					title: '缴存项目',
					meta: {
						title: '缴存项目',
					},
					menuHide: true,
					component: Layout,
					children: routerData
				}])
				store.commit('permission/SET_CURRENT_ROUTES', routerData)
				initRouter = true
				next({...to})
			} else {
				next()
			}
		} else {
			next()
		}
	}
})

export default router
