import {
	constantRoutes
} from '@/router'

let authority = JSON.parse(localStorage.getItem('authority'))

const state = {
	currentRoutes: authority || [],
}

const mutations = {
	SET_CURRENT_ROUTES: (state, routes) => {
		state.currentRoutes = routes
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
